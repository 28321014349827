import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { RADIX_COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const container = styled.section`
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${RADIX_COLOR.green3};

  .mobile-only {
    display: none;
  }

  ${mobile(
    css`
      height: 294px;
      padding: 0 16px;

      .mobile-only {
        display: block;
      }
    `
  )}
`;

const content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;

  h3 {
    ${setFontStyle("Head3")};
    color: ${RADIX_COLOR.green9};

    ${mobile(
      css`
        ${setFontStyle("Head6")};
      `
    )}
  }

  p {
    ${setFontStyle("Body2")};
    color: ${TEXT_COLOR.black_2};

    ${mobile(
      css`
        ${setFontStyle("Body3")};
      `
    )}
  }
`;

export default {
  container,
  content,
};
