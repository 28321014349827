import { FreightType } from "../../types/common/common";

const getBaseSupply = (freightType: FreightType | undefined) =>
  freightType
    ? {
        FCL: "20DRY",
        LCL: "1R.TON",
        AIR: "100C.W",
      }[freightType]
    : "-";

export { getBaseSupply };
