import { Dispatch, SetStateAction } from "react";

import SCHEDULE_QUERY from "@sellernote/_shared/src/queries/forwarding/SCHEDULE_QUERY";

export default function useGetHeroSectionRateList({
  setAbTestType,
}: {
  setAbTestType: Dispatch<SetStateAction<string>>;
}) {
  const { data: heroSectionScheduleList } =
    SCHEDULE_QUERY.useGetHeroSectionScheduleList({
      onError: () => {
        setAbTestType("A");
      },
    });

  return heroSectionScheduleList;
}
