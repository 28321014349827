import { useAtomValue } from "jotai";
import Router from "next/router";
import { useRecoilValue } from "recoil";

import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import Button from "@sellernote/_sds-v2/src/components/button/Button";
import FourChevronRightIcon from "@sellernote/_sds-v2/src/components/svgIcons/FourChevronRightIcon";
import { COMMON_MAIN_ATOMS } from "@sellernote/shipda-kr/src/jotaiStates/common/main";

import { useTranslation } from "../../../utils/i18n";

import Styled from "./index.styles";

export default function Registration({ abTestType }: { abTestType: string }) {
  const { t } = useTranslation(["common", "common-new"]);

  const { isMobile } = useCheckIsMobile();

  const loggedIn = useRecoilValue(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const isRegistrationButtonVisible = useAtomValue(
    COMMON_MAIN_ATOMS.IS_REGISTRATION_BUTTON_VISIBLE_ATOM
  );

  const isBTestType = abTestType === "B";

  return (
    <>
      {isMobile && !loggedIn && (
        <Styled.registrationWrapper
          abTestType={abTestType}
          className={
            isBTestType
              ? `${isRegistrationButtonVisible ? "is-visible" : ""}`
              : ""
          }
        >
          <Button
            theme="primary"
            borderType="filled"
            size="block"
            label={t("common-new:푸터_회원가입")}
            iconInfo={{
              Icon: FourChevronRightIcon,
              position: "right",
            }}
            handleClick={() => {
              Router.push("/register");
            }}
          />
        </Styled.registrationWrapper>
      )}
    </>
  );
}
