import Image from "next/image";

import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";

import Styled from "./index.styles";

export default function AEOSection() {
  const isMobile = useCheckIsMobile();

  return (
    <Styled.container>
      <Styled.content>
        <Image
          src="/assets/images/main/aeo.png"
          width={isMobile ? 68 : 85}
          height={isMobile ? 64 : 80}
          alt="AEO 인증"
        />

        <h3>
          안전하고 간단한 수출입 절차, <br className="mobile-only" />
          관세청이 보장합니다
        </h3>

        <p>
          국내 디지털 포워딩 스타트업 최초 관세청이 주도하는 AEO 인증으로 신속한
          수출입 절차에 안전성을 더했습니다.
          <br />
          보안·안전이 중요한 공급망, <br className="mobile-only" />
          물류 위험 관리를 쉽다와 함께 해보세요.
        </p>
      </Styled.content>
    </Styled.container>
  );
}
